<style>
  .kz-crafted {
    position: absolute;
    width: 100%;
  }
  .kz-para {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .kz-para span{
      color: var(--orange);
  }
  .kz-deepBlue {
    color: var(--deepBlue);
  }
  @media (max-width: 600px) {
    .kz-para {
      font-size: 3.7vw;
    }
    .kz-para span{
        color: var(--orange); 
        font-size: 20px;
    }
  }
  @media (max-width: 600px) {
      .kz-para {
      font-size: 3.7vw;
      }
  }
</style>

<div class="text-center kz-crafted">
  <p class="kz-para kz-deepBlue">
    crafted with
    <span>&hearts</span>
    by your friends on the SRMKZILLA team
  </p>
</div>
